import React from 'react'
import { Product } from '../../types'
import ImageMagnifier from '../../utils/Magnifier'


export default function ArtworkCard({ product }: { product: Product }) {
    
    
    
    
    return (
        <div className="artwork-card">
            <div className="top">
                <ImageMagnifier src={product.photos[0].url} height='150px' width='auto'/>
                <div className="artwork-card-content-top">
                    <p>{product.description}</p>
                </div>
            </div>
            <div className="bottom">
                <h1>{product.title}</h1>
            </div>
        </div>
    )
}
