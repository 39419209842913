import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Gallery from './pages/gallery/index'
import { DataProvider } from './contexts/DataContext'


function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>

          <Route path='*' element= { <Gallery /> }/>
          <Route path='/' element={ <Gallery /> }/>

        </Routes>
      </Router>
    </DataProvider>
  )
}

export default App;
