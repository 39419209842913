import React, { useState } from 'react'

interface MagnifierProps {
    src: string
    width?: string
    height?: string
    magnifierHeight?: number
    magnifierWidth?: number
    zoomLevel?: number
}

export default function ImageMagnifier({ src, width, height, magnifierHeight, magnifierWidth, zoomLevel }: MagnifierProps) {
  
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    
    return (
        <div style={{ 
            position: "relative", 
            height: height, 
            width: width,
        }}>
          <img 
            src={src} 
            style={{ height: height, width: width }} 
            alt={"img"}
            onMouseEnter={e => {
                const elem = e.currentTarget
                const { width, height } = elem.getBoundingClientRect()
                setSize([width, height])
                setShowMagnifier(true)
            }}
            onMouseMove={(e) => {
                const elem = e.currentTarget
                const { top, left } = elem.getBoundingClientRect()
      
                const x = e.pageX - left - window.pageXOffset
                const y = e.pageY - top - window.pageYOffset
                setXY([x, y])
            }}
            onMouseLeave={() => {
                setShowMagnifier(false)
            }}
        />
        <div 
            style={{
                display: showMagnifier ? "" : "none",
                position: "absolute",
      
                pointerEvents: "none",
                height: `${magnifierHeight || 100}px`,
                width: `${magnifierWidth || 100}px`,
                top: `${y - (magnifierHeight || 100) / 2}px`,
                left: `${x - (magnifierWidth || 100) / 2}px`,
                opacity: "1", // reduce opacity so you can verify position
                border: "1px solid lightgray",
                borderRadius: '100px',
                backgroundColor: "white",
                backgroundImage: `url('${src}')`,
                backgroundRepeat: "no-repeat",
      
                backgroundSize: `${imgWidth * (zoomLevel || 2)}px ${
                  imgHeight * (zoomLevel || 2)
                }px`,
      
                backgroundPositionX: `${-x * (zoomLevel || 2) + (magnifierWidth || 100) / 2}px`,
                backgroundPositionY: `${-y * (zoomLevel || 2) + (magnifierHeight || 100) / 2}px`
              }}
        />
        </div>
      )
}
