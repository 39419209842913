import React from 'react'
import { useData } from '../../contexts/DataContext'
import ArtworkCard from './ArtworkCard'

export default function Gallery() {
    
    const { products } = useData()
    
    
    
    
    
    return (
    <>
        <section id='heroSection'>
            <div className='container hero-container'>
                <div className="hero-header">
                    <h1>Mona s Art <br/> <span className='highlight'>Gallery</span></h1>
                    <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis, obcaecati quasi. Magni libero quia inventore quod iste aperiam dolore voluptates? Odio voluptates ducimus nulla beatae facilis non culpa voluptas rem!
                    </p>
                </div>
            </div>
        </section>
        <section id="productsSection">
            <div className="container products-container">
                {products?.map(product => {
                    return <ArtworkCard key={product.id} product={product}/>
                })}
            </div>
        </section>
    </>
    )
}
