import React, { useContext, useEffect, useState } from 'react'
import { GraphQLClient, gql } from 'graphql-request'
import { Product } from '../types'

const graphcms = new GraphQLClient('https://api-eu-central-1.graphcms.com/v2/cl3v7b5uveo9s01z6d0n832fb/master')
const QUERY = gql`
{
    artworks(orderBy: updatedAt_ASC) {
      id
      price
      slug
      sold
      title
      description
      photos {
        url
      }
    }
  }
`

interface useDataProps {
    products?: Product[]
}



const DataContext = React.createContext<useDataProps>({})

export function useData() {
    return useContext(DataContext)
}

export function DataProvider({ children }: { children: React.ReactNode }) {

    const [products, setProducts] = useState<Product[]>([])



    useEffect(() => {
        (async () => {

            const data = await graphcms.request(QUERY)
            setProducts([...data.artworks])

        })()
    }, [])




    const value: useDataProps = { 
        products
    }

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    )
}